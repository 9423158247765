<template>
  <div>
    <b-form-select
      :options="egressProfileCycles"
      v-model="selected_cycle"
      size="sm"
      value-field="id"
      text-field="name"
      @change="slotCycleUpdated"
      :disabled="disabled || !isNaN(cycle_id) || !can_edit_cycle"
    ></b-form-select>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  props: {
    cycle_id: { type: Number },
    egress_profile_id: { type: Number, required: true },
    // Se quito el Type:Number dado que puede llegar un egress_profile_competence_id sin id
    // dado que se le coloco un id generico para poder ver a quien agregarle el ciclo modificado, puede llegar
    // tanto un String como un Number
    egress_profile_competence_id: {},
    disabled: { type: Boolean, default: false },
    can_edit_cycle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected_cycle: this.cycle_id ? this.cycle_id : null,
    };
  },
  computed: {
    ...mapGetters({
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      profile_cycles: names.PROFILE_CYCLES,
      profileCompetences: names.PROFILE_COMPETENCES,
    }),
    egressProfileCompetence() {
      return this.profileCompetences.find(
        (x) => x.id == this.egress_profile_competence_id
      );
    },
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
    egressProfileCycles() {
      let cycles = [{ id: null, name: "-- No Aplica --" }];
      if (!this.egressProfile) return cycles;
      this.egressProfileCycleId.forEach((cycle_id) => {
        const cycle = this.cycles.find((x) => x.id == cycle_id);
        if (cycle) cycles.push(cycle);
      });
      return cycles;
    },
  },
  methods: {
    slotCycleUpdated() {
      // Emite la senal updated cuando se modifica el selector de ciclos y envia el id del ciclo y el del perfil de egreso.
      this.$emit(
        "updated",
        this.selected_cycle,
        this.egress_profile_competence_id
      );
    },
  },
  mounted() {
    if (this.egressProfileCompetence)
      this.selected_cycle = this.egressProfileCompetence.cycle;
    this.slotCycleUpdated();
  },
};
</script>

<style scoped>
</style>